import React, { useEffect, useState, useCallback } from 'react'
import { Loader } from '@refera/ui-web'
import { Grid, Typography } from '@mui/material'

import useStyles from './styles'
import { useLocation, useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { getBudgetByIdSelector } from '_/modules/budget/selectors'
import { serviceOrderCurrentSelector } from '_/modules/service-orders/selectors'
import { usePrevious } from '_/hooks/use-previous'
import useRolePermission from '_/hooks/use-role-permission'
import {
  authenticateTokenErrorSelector,
  authenticateTokenLoadingSelector,
} from '_/modules/authentication/selectors'
import { authenticateToken } from '_/modules/authentication/actions'

import { getBudget } from '_/modules/budget/actions'
import { getServiceOrder } from '_/modules/service-orders/actions'
import HeaderTitle from '_/components/header-title'
import { displayCurrency } from '_/utils/helpers'
import { ROUTES } from '_/utils/constants'

export function NFForm() {
  const styles = useStyles()

  const urlParams = useParams()
  const dispatch = useDispatch()
  const { search, state } = useLocation()

  const token = search.split('verification_token=')[1]

  const isAuthenticatingToken = useSelector(authenticateTokenLoadingSelector)
  const wasAuthenticatingToken = usePrevious(isAuthenticatingToken)
  const authenticateTokenError = useSelector(authenticateTokenErrorSelector)

  const { isTradesman } = useRolePermission()
  const { serviceOrderId, budgetId } = urlParams

  const serviceOrder = useSelector(serviceOrderCurrentSelector)
  const budget = useSelector(getBudgetByIdSelector(budgetId))?.toJS()

  const roundNumber = value => Math.round((value + Number.EPSILON) * 100) / 100
  const handleValueByPercentage = (percentage, value) =>
    roundNumber((percentage * value) / 100.0) || 0

  const priceAgency = handleValueByPercentage(
    budget?.agencyCompletionPercentage,
    budget?.priceServiceProvider
  )
  const priceRefera = handleValueByPercentage(
    budget?.referaCompletionPercentage,
    budget?.priceServiceProvider
  )
  const partialPrice = budget?.priceServiceProvider + priceAgency + priceRefera
  const financialIncomeValue = handleValueByPercentage(budget?.financialIncome, partialPrice)
  const priceTotal = budget?.priceServiceProvider + priceAgency + priceRefera + financialIncomeValue
  const finalPrice = priceTotal - handleValueByPercentage(budget?.withholdingTax, priceTotal)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isTradesman && search) {
      dispatch(authenticateToken({ verificationToken: token }))
    }
  }, [dispatch, isTradesman, search])

  useEffect(() => {
    if (
      isTradesman &&
      (!token || (!isAuthenticatingToken && wasAuthenticatingToken && authenticateTokenError.size))
    ) {
      navigate('/link-expirado')
    }
  }, [
    authenticateTokenError.size,
    dispatch,
    isAuthenticatingToken,
    serviceOrderId,
    wasAuthenticatingToken,
    token,
  ])

  const handleBackNavigation = useCallback(() => {
    const backNavigationUrl =
      state?.from || `${ROUTES.BUDGET_VIEW}/${budgetId}?verification_token=${token}`
    navigate(backNavigationUrl)
  }, [state?.from, budgetId, serviceOrderId, token])

  const handleTranslateTaxRegime = regime => {
    return budget?.taxRegimes?.find(tax => tax?.value === regime)?.label || 'Não informado'
  }

  const verificateRecipientInvoice = value => {
    if (value === 'other') {
      return budget?.budgetPayer?.[0]?.invoiceDocNumber
    }
    return budget?.budgetPayer?.[0]?.responsibleDocNumber
      ? budget?.budgetPayer?.[0]?.responsibleDocNumber
      : budget?.personPayingCpf || 'Não informado'
  }

  useEffect(() => {
    setLoading(true)
    if (serviceOrderId && budgetId && !budget) {
      dispatch(getBudget(serviceOrderId, budgetId)).then(() => {
        setLoading(false)
      })
    }

    if (serviceOrderId && !serviceOrder) {
      dispatch(getServiceOrder(serviceOrderId)).then(() => {
        setLoading(false)
      })
    }

    setLoading(false)
  }, [budget, budgetId, dispatch, serviceOrderId, serviceOrder])

  return (
    <Grid className={styles.container}>
      <Loader open={loading} hasBackdrop label="Aguarde..." />

      <HeaderTitle title="Dados da NFS-e" backButtonAction={handleBackNavigation} />
      <Grid className={styles.form}>
        <Grid className={styles.field}>
          <Typography className={styles.subTitle}>Dados do pagador</Typography>
          <Grid className={styles.content}>
            <Grid className={styles.card}>
              <Typography className={styles.label}>Nome do responsável pelo pagamento</Typography>
              <Typography className={styles.input}>
                {budget?.budgetPayer?.[0]?.recipientInvoice === 'other'
                  ? budget?.budgetPayer?.[0]?.nameInvoice
                  : budget?.budgetPayer?.[0]?.responsibleName || 'Não informado'}
              </Typography>
            </Grid>

            <Grid className={styles.card}>
              <Typography className={styles.label}>CPF/CNPJ</Typography>
              <Typography className={styles.input}>
                {verificateRecipientInvoice(budget?.budgetPayer?.[0]?.recipientInvoice)}
              </Typography>
            </Grid>

            <Grid className={styles.card}>
              <Typography className={styles.label}>Regime Tributário</Typography>
              <Typography className={styles.input}>
                {handleTranslateTaxRegime(budget?.budgetPayer?.[0]?.regime)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={styles.field}>
          <Typography className={styles.subTitle}>Valores da nota</Typography>
          <Grid className={styles.content}>
            <Grid className={styles.card}>
              <Typography className={styles.label}>Parcelamento</Typography>
              <Typography className={styles.input}>
                {budget?.budgetPayer?.[0]?.installmentQuantity > 1
                  ? `Parcelamento via intermediária (${budget?.budgetPayer?.[0]?.installmentQuantity}x)`
                  : 'Sem parcelamento'}
              </Typography>
            </Grid>

            <Grid className={styles.grid}>
              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Valor do prestador</Typography>
                  <Typography className={styles.input}>
                    {displayCurrency(budget?.priceServiceProvider)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Valor da intermediária</Typography>
                  <Typography className={styles.input}>{displayCurrency(priceAgency)}</Typography>
                </Grid>
              </Grid>

              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Valor da Refera</Typography>
                  <Typography className={styles.input}>
                    {displayCurrency(priceRefera + financialIncomeValue)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Valor bruto</Typography>
                  <Typography className={styles.input}>{displayCurrency(priceTotal)}</Typography>
                </Grid>
              </Grid>

              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Imposto retido</Typography>
                  <Typography className={[styles.redText, styles.input]}>
                    {`- `}{' '}
                    {displayCurrency(handleValueByPercentage(budget?.withholdingTax, priceTotal))}
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={styles.column}>
                <Grid className={styles.card}>
                  <Typography className={styles.label}>Valor líquido</Typography>
                  <Typography className={styles.input}>{displayCurrency(finalPrice)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
